import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

// Utilities
import kebabCase from "lodash/kebabCase"

import Layout from "../components/layout"
import SEO from "../components/seo"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <article
          className={`post-content ${post.frontmatter.thumbnail || `no-image`}`}
        >
          <header className="post-content-header">
            <h1 className="post-content-title">{post.frontmatter.title}</h1>
          </header>

          {post.frontmatter.description && (
            <p className="post-content-excerpt">{post.frontmatter.description}</p>
          )}

          {post.frontmatter.thumbnail && (
            <div className="post-content-image">
              <GatsbyImage
                image={post.frontmatter.thumbnail.childImageSharp.gatsbyImageData}
                className="kg-image"
                alt={post.frontmatter.title} />
            </div>
          )}

          <div
            className="post-content-body"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />

          <hr />
          {post.frontmatter.tags && (
            <p>
              Tagged: {" "}
              {post.frontmatter.tags.map( t  => {
                return (
                    <div className="post-content-tag">
                      <Link to={`/tags/${kebabCase(t)}`}>
                        {t}
                      </Link>
                    </div>
                )
              }

              )}
              <div className="blog-card-date">{post.frontmatter.date}</div>
            </p>
          )}

          <footer className="post-content-footer">
          </footer>
        </article>
      </Layout>
    );
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`query BlogPostBySlug($slug: String!) {
  site {
    siteMetadata {
      title
      author
    }
  }
  markdownRemark(fields: {slug: {eq: $slug}}) {
    id
    excerpt(pruneLength: 160)
    html
    frontmatter {
      tags
      title
      date(formatString: "MMMM DD, YYYY")
      description
      thumbnail {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
}
`
